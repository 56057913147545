@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@400;700&display=swap');

/* Custom classes */
@layer components {
    .fade-enter {
        @apply opacity-5 transition-opacity duration-300 ease-in;
    }
    .fade-enter-active {
        @apply opacity-100 transition-opacity duration-300 ease-in;
    }
    .fade-exit {
        @apply opacity-100 transition-opacity duration-300 ease-in;
    }
    .fade-exit-active {
        @apply opacity-5 transition-opacity duration-300 ease-in;
    }
}